 export const skillsData = [
    'C#',
    'Angular',
    'TypeScript',
    'Figma',
    'Django',
    'Vue.Js',
    'React',
    'Python',
]
