export const experienceData = [
    {
        id: 1,
        company: 'Veterans United',
        jobtitle: 'Software Engineer',
        startYear: 'May 2023',
        endYear: 'Present'
    },
    {
        id: 2,
        company: 'Veterans United',
        jobtitle: 'Software Engineer Intern',
        startYear: 'May 2022',
        endYear: 'May 2023'
    },
    {
        id: 3,
        company: 'Central Technology Services',
        jobtitle: 'Java Programming Intern',
        startYear: 'June 2021',
        endYear: 'August 2021'
    },
]