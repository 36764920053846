export const educationData = [
    {
        id: 1,
        institution: 'Bachelor of Engineering - Computer Science, GPA 3.74',
        course: 'Univerity of Missouri',
        startYear: 'August 2019',
        endYear: 'May 2023'
    },
    {
        id: 1,
        institution: 'GPA 3.95',
        course: 'Rock Bridge High School',
        startYear: 'August 2015',
        endYear: 'May 2019'
    },
]