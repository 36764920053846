import { FaCoffee } from 'react-icons/fa'
import one from '../assets/svg/projects/five.svg'
import two from '../assets/svg/projects/three.svg'
import ten from '../assets/svg/projects/ten.svg'


export const projectsData = [
    {
        id: 1,
        projectName: 'Palette Painter',
        projectDesc: 'A sample application utilizing Python, Flask, and JavaScript that allows you to draw/create images over your current camera image',
        tags: ['Python', 'Flask', 'JavaScript'],
        code: 'https://github.com/jstepanek/palette-painter',
        image: two
    },
    {
        id: 2,
        projectName: 'Virtual Coffee Shop',
        projectDesc: 'Frontend for a virtual coffee shop utilizing Angular and TypeScript',
        tags: ['Angular', 'CSS', 'TypeScript'],
        code: 'https://github.com/jstepanek/cwoffee-abound-frontend',
        image: ten
    },
    {
        id: 3,
        projectName: 'React Realtime Chat Engine',
        projectDesc: 'A scalable, realtime chat app with React, Node.Js, Django, and Vue',
        tags: ['React', 'Node.Js', 'Django', 'Vue'],
        code: 'https://github.com/jstepanek/react-chat-engine',
        image: one
    },
]